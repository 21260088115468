<template>
  <div class="PlayerDetail">
    <BreadcrumbVue :navigatelist="navigatelist" />
    <div class="tips">玩家信息</div>
    <div class="tablewrap">
      <el-table
        :data="tableData"
        style="width: 100%"
        empty-text="还没有数据哦~"
        stripe
      >
        <el-table-column
          v-for="(item, index) in tablecolumn"
          :key="index"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
          :sortable="item.sortable"
        >
          <template #default="scope">
            <div v-if="scope.row[item.prop] !== 0 && !scope.row[item.prop]">
              -
            </div>
            <div v-else-if="item.prop == 'createTime'">
              {{ dayjs(scope.row[item.prop]).format("YYYY-MM-DD HH:mm:ss") }}
            </div>
            <div v-else-if="item.prop == 'integral'">
              {{ scope.row[item.prop] }}
            </div>
            <div v-else>{{ scope.row[item.prop] }}</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="tips">邀请函详情</div>
    <div class="tablewrap">
      <el-table
        :data="letterOfInvitationData"
        style="width: 100%"
        empty-text="还没有数据哦~"
        stripe
      >
        <el-table-column
          v-for="(item, index) in letterOfInvitationTitle"
          :key="index"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
          :sortable="item.sortable"
        >
          <template #default="scope">
            <div v-if="scope.row[item.prop] !== 0 && !scope.row[item.prop]">
              -
            </div>
            <div v-else-if="item.prop == 'invitationType'">{{ scope.row.invitationType == 1?'赠送':'自用' }}</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="paginationwrap">
      <el-pagination
        class="paging fr"
        @current-change="letterOfInvitationHandleCurrentChange"
        :current-page="letterOfInvitationPage"
        @size-change="letterOfInvitationHandleSizeChange"
        :page-sizes="[10, 20, 50, 100, 200]"
        :page-size="letterOfInvitationSize"
        :pager-count="5"
        layout="total, sizes, prev, pager, next, jumper"
        :total="letterOfInvitationTotal"
      >
      </el-pagination>
    </div>
    <div class="tips">J-Dice碎片详情</div>
    <div class="settlement" v-if="tableData.length">
      <div class="item">当前碎片数：{{tableData[0].jdiceDebrisCount}}</div>
      <div class="item">兑换次数：{{tableData[0].exchangeCount}}</div>
    </div>
    <div class="tablewrap">
      <el-table
        :data="fragmentsData"
        style="width: 100%"
        empty-text="还没有数据哦~"
        stripe
      >
        <el-table-column
          v-for="(item, index) in fragmentsTitle"
          :key="index"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
          :sortable="item.sortable"
        >
          <template #default="scope">
            <div v-if="item.prop == 'transactionType'">
              {{scope.row.transactionType == 1?'兑换':'获取'}}
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="paginationwrap">
      <el-pagination
        class="paging fr"
        @current-change="fragmentsHandleCurrentChange"
        :current-page="fragmentsPage"
        @size-change="fragmentsHandleSizeChange"
        :page-sizes="[10, 20, 50, 100, 200]"
        :page-size="fragmentsSize"
        :pager-count="5"
        layout="total, sizes, prev, pager, next, jumper"
        :total="fragmentsTotal"
      >
      </el-pagination>
    </div>
    <div class="tips">六维特性详情</div>
    <div class="sixDimensions">
      <div class="item">
        <div id="main" style="width: 600px; height: 600px"></div>
      </div>
      <div class="right">
        <div class="tablewrap">
          <el-table
            :data="voteOneData"
            style="width: 100%"
            empty-text="还没有数据哦~"
            stripe
          >
            <el-table-column
              v-for="(item, index) in voteOneTitle"
              :key="index"
              :prop="item.prop"
              :label="item.label"
              :width="item.width"
              :sortable="item.sortable"
            >
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="120">
              <template  #default="scope">
                <el-button type="text" size="small" @click="showVote(scope.row)"> 投票记录 </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <el-dialog
        title="投票记录"
        v-model="isVote"
        width="1000px"
        :before-close="cancelVote"
    >
      <div class="tablewrap">
        <el-table
            :data="voteData"
            style="width: 100%"
            empty-text="还没有数据哦~"
            stripe
        >
          <el-table-column
              v-for="(item, index) in voteTitle"
              :key="index"
              :prop="item.prop"
              :label="item.label"
              :width="item.width"
          >
            <template #default="scope">
              <div v-if="scope.row[item.prop] !== 0 && !scope.row[item.prop]">
                -
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="paginationwrap">
        <el-pagination
            class="paging fr"
            @current-change="voteHandleCurrentChange"
            :current-page="votePage"
            @size-change="voteHandleSizeChange"
            :page-sizes="[10, 20, 50, 100, 200]"
            :page-size="voteSize"
            :pager-count="5"
            layout="total, sizes, prev, pager, next, jumper"
            :total="voteTotal"
        >
        </el-pagination>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
import { onMounted, reactive, ref } from "vue";
import BreadcrumbVue from "@/components/breadcrumb.vue";
import { useRouter } from "vue-router";
import { get } from "../../utils/request";
import { Decrypt } from "../../utils/secret";
import dayjs from "dayjs";
import * as echarts from "echarts";
const router = useRouter();
// 玩家详情
const navigatelist = [
  { key: 1, value: "玩家" },
  { key: 2, value: "玩家信息" },
  { key: 3, value: "玩家详情" },
];
let tableData = ref([]);
const tablecolumn = reactive([
  {
    prop: "id",
    label: "玩家编号",
    width: "",
  },
  {
    prop: "nickname",
    label: "昵称",
    width: "",
  },
  {
    prop: "integral",
    label: "积分",
    width: "",
  },
  {
    prop: "grade",
    label: "等级",
    width: "",
  },
  {
    prop: "ranking",
    label: "排名",
    width: "",
  },
  {
    prop: "createTime",
    label: "创建时间",
    width: "200",
  },
  {
    prop: "introduce",
    label: "签名",
    width: "",
  },
]);
// 邀请函数据
const letterOfInvitationTitle = reactive([
  {
    prop: "invitationName",
    label: "邀请函名称",
    width: "",
  },
  {
    prop: "invitationType",
    label: "类型",
    width: "",
  },
  {
    prop: "startDate",
    label: "起始日",
    width: "",
  },
  {
    prop: "expirationDate",
    label: "到期日",
    width: "",
  },
  {
    prop: "remark",
    label: "来源",
    width: "",
  },
  {
    prop: "issuanceTime",
    label: "获得时间",
    width: "",
  },
  {
    prop: "useTime",
    label: "使用时间",
    width: "",
  },
]);
const letterOfInvitationData = ref([]);
const letterOfInvitationPage = ref(1);
const letterOfInvitationSize = ref(10);
const letterOfInvitationTotal = ref(0);
const letterOfInvitationHandleCurrentChange = (val) => {
  letterOfInvitationPage.value = val;
  getLetterOfInvitationList();
};
const letterOfInvitationHandleSizeChange = (val) => {
  letterOfInvitationSize.value = val;
  getLetterOfInvitationList();
};
const getLetterOfInvitationList = async () => {
  const { result } = await get(`/admin/TinvitationUser/page?page=${letterOfInvitationPage.value}&size=${letterOfInvitationSize.value}&userId=${tableData.value[0].id}`)
  letterOfInvitationData.value = result.list
  letterOfInvitationTotal.value = result.total
};
// J-Dice碎片详情
const fragmentsTitle = reactive([
  {
    prop: "transactionTime",
    label: "交易时间",
    width: "",
  },
  {
    prop: "transactionType",
    label: "交易类型",
    width: "",
  },
  {
    prop: "debrisQuantity",
    label: "碎片数量",
    width: "",
  },
  {
    prop: "description",
    label: "交易说明",
    width: "",
  },
]);
const fragmentsData = ref([]);
const fragmentsPage = ref(1);
const fragmentsSize = ref(10);
const fragmentsTotal = ref(0);
const jdiceDebrisCount = ref(0)
const exchangeCount = ref(0)
const fragmentsHandleCurrentChange = (val) => {
  fragmentsPage.value = val;
  fragmentsList();
};
const fragmentsHandleSizeChange = (val) => {
  fragmentsSize.value = val;
  fragmentsList();
};
const fragmentsList = async () => {
  const { result } = await get(`/admin/j_dice_debri/page?page=${fragmentsPage.value}&size=${fragmentsSize.value}&userId=${tableData.value[0].id}`)
  fragmentsData.value = result.records
  jdiceDebrisCount.value = result.jdiceDebrisCount || 0
  exchangeCount.value = result.exchangeCount || 0
  fragmentsTotal.value = result.total
};
// 六维特性数据
const echartsData = ref([])
const voteOneTitle = reactive([
  {
    prop: "title",
    label: "",
    width: "",
  },
  {
    prop: "propertyCount",
    label: "票数",
    width: "",
  },
  {
    prop: "proportionOf",
    label: "占比",
    width: "",
  },
]);
const voteOneData = ref([]);
const getPropertyDetail = async () => {
  const { result } = await get(`/admin/property_record/propertyDetail?userId=${tableData.value[0].id}`)
  const totalCount = result[0].totalCount || 0
  voteOneData.value = [
    {
      id: 1,
      title: "悟",
      propertyCount: result[0].propertyCount1,
      proportionOf: totalCount ? (result[0].propertyCount1 / totalCount * 100).toFixed(1) + "%" : 0 + "%",
    },
    {
      id: 2,
      title: "谋",
      propertyCount: result[0].propertyCount2,
      proportionOf: totalCount ? (result[0].propertyCount2 / totalCount * 100).toFixed(1) + "%" : 0 + "%",
    },
    {
      id: 3,
      title: "联",
      propertyCount: result[0].propertyCount3,
      proportionOf: totalCount ? (result[0].propertyCount3 / totalCount * 100).toFixed(1) + "%" : 0 + "%",
    },
    {
      id: 4,
      title: "魅",
      propertyCount: result[0].propertyCount4,
      proportionOf: totalCount ? (result[0].propertyCount5 / totalCount * 100).toFixed(1) + "%" : 0 + "%",
    },
    {
      id: 5,
      title: "奇",
      propertyCount: result[0].propertyCount5,
      proportionOf: totalCount ? (result[0].propertyCount5 / totalCount * 100).toFixed(1) + "%" : 0 + "%",
    },
    {
      id: 6,
      title: "运",
      propertyCount: result[0].propertyCount6,
      proportionOf: totalCount ? (result[0].propertyCount6 / totalCount * 100).toFixed(1) + "%" : 0 + "%",
    },
  ]
  createEcharts(result[0])
}
const createEcharts = (data) => {
  const myChart = echarts.init(document.getElementById("main"));
  let option = {
    tooltip: {
      trigger: "item",
    },
    legend: {
      show: false,
    },
    title: {
      text: tableData.value[0].nickname,
      left: 'center',
      top: '40%',
      overflow: 'truncate',
      textStyle: {
        color: '#27D9C8',
        fontSize: 40,
        align: 'center',
        fontWeight: 700,
        overflow: 'truncate',
        width: 200
      }
    },
    graphic: {
      type: 'text',
      left: 'center',
      top: '55%',
      style: {
        text: '博弈游戏六维特性',
        textAlign: 'center',
        fill: '#000',
        fontSize: 26,
        fontWeight: 400
      }
    },
    series: [
      {
        name: "Access From",
        type: "pie",
        radius: ["60%", "50%"],
        avoidLabelOverlap: false,
        emphasis: {
          label: {
            show: true,
            fontSize: 40,
            fontWeight: "bold",
            formatter: "{c}\n({d}%)",
          },
        },
        label: {
          normal: {
            formatter: function (params) {
              // 计算每一部分的百分比
              var percent = data.totalCount ? (params.value / data.totalCount) * 100 : 0;
              // 将百分比转换为整数
              percent = Math.floor(percent);
              // 返回换行的文本
              return ["{b|" + params.name + "}\n{a|" + percent + "%}"].join(
                "\n"
              );
            },
            rich: {
              a: {
                height: 40,
                align: "center",
                padding: [0, 0, 0, 0],
                color: "#000",
                fontSize: 16,
              },
              b: {
                align: "center",
                padding: [0, 0, 0, 0],
                color: "#000",
                fontSize: 14,
              },
            },
          },
        },
        labelLine: {
          normal: {
            show: true,
            length: 50, // 这里设置线条长度
            length2: 20, // 文字到图形的线长度
            smooth: 0.2,
            // 线的样式
            lineStyle: {
              width: 1,
              type: "solid",
            },
          },
        },
        data: [
          { value: data.propertyCount1, name: "悟" },
          { value: data.propertyCount2, name: "谋" },
          { value: data.propertyCount3, name: "联" },
          { value: data.propertyCount4, name: "魅" },
          { value: data.propertyCount5, name: "奇" },
          { value: data.propertyCount6, name: "运" },
        ],
        silent: true,
      },
    ],
  };
  myChart.setOption(option);
};

// 投票记录
const isVote = ref(false)
const voteTitle = reactive([
  {
    prop: "createTime",
    label: "投票时间",
    width: "",
  },
  {
    prop: "count",
    label: "票数",
    width: "",
  },
  {
    prop: "gameSessionId",
    label: "场次编号",
    width: "",
  },
  {
    prop: "gameName",
    label: "游戏",
    width: "",
  },
  {
    prop: "userName",
    label: "投票人",
    width: "",
  },
]);
const voteData = ref([]);
const votePage = ref(1);
const voteSize = ref(10);
const voteTotal = ref(0);
const voteId = ref(0)
const showVote = (e) => {
  isVote.value = true
  voteId.value = e.id
  voteList()
}
const voteHandleCurrentChange = (val) => {
  votePage.value = val;
  voteList();
};
const voteHandleSizeChange = (val) => {
  voteSize.value = val;
  voteList();
};
const voteList = async () => {
  let url = `/admin/property_record/propertyRecord?page=${votePage.value}&size=${voteSize.value}`
  url += `&userId=${tableData.value[0].id}&propertyConfigId=${voteId.value}`
  const { result } = await get(url)
  voteData.value = result.records
  voteTotal.value = result.total
};
const cancelVote = () => {
  isVote.value = false
};
onMounted(() => {
  //先解码再解密
  if (router.currentRoute.value.query.list) {
    const encodedData = JSON.parse(
      Decrypt(decodeURIComponent(router.currentRoute.value.query.list))
    );
    console.log(encodedData);
    tableData.value.push(encodedData);
  }
  // createEcharts();
  getPropertyDetail()
  fragmentsList()
  getLetterOfInvitationList()
});
</script>

<style lang="less" scoped>
.PlayerDetail {
  width: 98%;
  padding: 10px 10px;
}
.flexbox {
  display: flex;
  align-items: center;
  justify-content: center;
}
.tablewrap {
  //margin-top: 20px;
}
.paginationwrap {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: flex-end;
}
.tips {
  margin: 20px 0;
  font-weight: bold;
}
.settlement {
  display: flex;
  .item {
    width: 50%;
    margin-bottom: 20px;
  }
}
.sixDimensions {
  display: flex;
  align-items: center;
  .right {
    width: 500px;
  }
}
</style>
