<template>
  <router-view/>
</template>
<style lang="less">
@import "@/assets/app.less";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
html, body, #app {
  /*color: #606266;*/
  width: 100%;
  height: 100%;
  font-family: 'Microsoft YaHei', 'Avenir', Helvetica, Arial, sans-serif;
  overflow: hidden;
}
.el-select .el-tag__close {
  display: none;
}
.letterOfInvitation{
  .avatar-uploader .el-upload {
    border: 1px dashed #999;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 20px;
    color: #8c939d;
    width: 120px;
    height: 35px;
    line-height: 24px;
    text-align: center;
  }
  .avatar {
    width: 120px;
    height: 35px;
    display: block;
  }
}
</style>
