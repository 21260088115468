import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'element-plus/dist/index.css'
import locale from 'element-plus/es/locale/lang/zh-cn';
import { ElMessage } from "element-plus";
import mitt from 'mitt'
const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.config.globalProperties.mitt = mitt()
app.config.globalProperties.message = ElMessage
app.use(store);
app.use(router);
app.use(ElementPlus, { locale });
app.mount('#app');

