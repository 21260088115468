<template>
    <div class="Homepage">
        <div style="width:46%;height:500px">
            <div style="text-align:center">今日游戏局</div>
            <div class="flexbox">
               <span>{{gameBoardSum}}</span> 
                    <el-icon v-if="gameBoardStatus == 1"><CaretTop /></el-icon>
                    <el-icon v-else-if="gameBoardStatus == 0"><SemiSelect /></el-icon>
                    <el-icon v-else-if="gameBoardStatus == 2"><CaretBottom /></el-icon>
               <span>{{gameBoard}}</span> 
            </div>
            <div id='leftChart' style="width:100%;height:100%"></div>
        </div>
        <div style="width:46%;height:500px">
            <div style="text-align:center">今日玩家数</div>
            <div class="flexbox">
               <span>{{playerNumberSum}}</span>
                    <el-icon v-if="gameBoardStatus == 1"><CaretTop /></el-icon>
                    <el-icon v-else-if="gameBoardStatus == 0"><SemiSelect /></el-icon>
                    <el-icon v-else-if="gameBoardStatus == 2"><CaretBottom /></el-icon>
               <span>{{playerNumber}}</span> 
            </div>
            <div id='rightChart' style="width:100%;height:100%"></div>
        </div>
        
    </div>
</template>

<script setup>
import * as echarts from 'echarts';
import { onMounted, reactive, ref ,onBeforeUnmount} from 'vue'
import { get } from '../../utils/request'
const resizeHandlerRef = ref(null);
const resizeHandlerRef2 = ref(null);
const gameBoard= ref(0);
const gameBoardSum= ref(0);
const playerNumber= ref(0);
const playerNumberSum= ref(0);
const gameBoardStatus=ref(0);
const playerNumberStatus=ref(0)
onMounted(()=>{
    getData()
})
onBeforeUnmount(()=>{
     window.removeEventListener('resize',resizeHandlerRef.value);
     window.removeEventListener('resize',resizeHandlerRef2.value);
})
const getData = ()=>{
    get('/manager/data/statistics').then(res=>{
        if(res.code == 1){
            gameBoard.value = res.result.gameBoard
            playerNumber.value =res.result.playerNumber
            playerNumberStatus.value =res.result.playerNumberStatus
            gameBoardStatus.value =res.result.gameBoardStatus
            gameBoardSum.value =res.result.list[0].gameBoard
            playerNumberSum.value =res.result.list[0].playerNumber
            let xdata = [],yldata=[],yrdata=[]
            res.result.list.map(item=>{
                xdata.push(item.date)
                yldata.push(item.gameBoard)
                yrdata.push(item.playerNumber)
            })
            xdata.reverse()
            yldata.reverse()
            yrdata.reverse()
            leftChart(xdata,yldata)
            rightChart(xdata,yrdata)
        }
    })
}
const leftChart = (xdata,ydata)=>{
    var chartDom = document.getElementById('leftChart');
    var myChart = echarts.init(chartDom);
    var option;

    option = {
    xAxis: {
        type: 'category',
        data: xdata,
        axisLabel: {
            fontSize: 12, // 设置 x 轴字体大小
            interval: 0, // 强制显示所有刻度标签
            rotate: 45, // 设置 x 轴刻度标签旋转角度
        },
    },
    yAxis: {
        type: 'value'
    },
    series: [
        {
        data: ydata,
        type: 'bar'
        }
    ]
    };
    option && myChart.setOption(option);
    resizeHandlerRef.value=()=>{
        console.log(2)
        myChart.resize();
    }
    window.addEventListener('resize', resizeHandlerRef.value);
}
const rightChart = (xdata,ydata)=>{
    var chartDom = document.getElementById('rightChart');
    var myChart = echarts.init(chartDom);
    var option;

    option = {
    xAxis: {
        type: 'category',
        data: xdata,
        axisLabel: {
            fontSize: 12, // 设置 x 轴字体大小
            interval: 0, // 强制显示所有刻度标签
            rotate: 45, // 设置 x 轴刻度标签旋转角度
        },
    },
    yAxis: {
        type: 'value'
    },
    series: [
        {
        data: ydata,
        type: 'bar'
        }
    ]
    };
    option && myChart.setOption(option);
    resizeHandlerRef2.value=()=>{
        console.log(1)
        myChart.resize();
    }
    window.addEventListener('resize', resizeHandlerRef2.value);
}
</script>

<style lang="less" scoped>
    .Homepage{
        width: 98%;
        padding: 30px 10px; 
        display: flex;
    }
    .flexbox{
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>