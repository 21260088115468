<template>
	<div class="GameLogList">
		<BreadcrumbVue :navigatelist="navigatelist" />
		<el-tabs
			v-model="activeName"
			class="demo-tabs"
			@tab-click="handleClick"
			style="margin-top: 20px"
		>
			<el-tab-pane label="日志详情" name="日志详情">
				<div style="margin-top: 20px" class="formhead">
					<div class="row">
						<el-form-item label="游戏回合">
							<el-select v-model="storeId" placeholder="请选择游戏回合" style="marginleft: 20px">
								<el-option
									:label="item.label"
									:value="item.value"
									v-for="item in storeList"
									:key="item.value"
								/>
							</el-select>
						</el-form-item>
					</div>
					<div class="btngroup">
						<el-button type="primary" @click="handleDownload">下载数据</el-button>
						<el-button @click="handleReset">重置</el-button>
						<el-button type="primary" @click="handleSearch">搜索</el-button>
					</div>
					<div class="tablewrap">
						<el-table
							:data="tableData"
							@sort-change="handleSortChange"
							style="width: 100%"
							empty-text="还没有数据哦~"
							stripe
						>
							<el-table-column
								v-for="(item, index) in tablecolumn"
								:key="index"
								:prop="item.prop"
								:label="item.label"
								:width="item.width"
								:sortable="item.sortable"
							>
								<template #default="scope">
									<div v-if="scope.row[item.prop] !== 0 && !scope.row[item.prop]">-</div>
									<div v-else-if="item.prop == 'time'">
										{{ dayjs(scope.row[item.prop]).format("YYYY-MM-DD HH:mm:ss") }}
									</div>
									<div v-else>{{ scope.row[item.prop] }}</div>
								</template>
							</el-table-column>
						</el-table>
					</div>
					<div class="paginationwrap">
						<el-pagination
							class="paging fr"
							@current-change="handleCurrentChange"
							:current-page="page"
							@size-change="handleSizeChange"
							:page-sizes="[10, 20, 50, 100, 200]"
							:page-size="size"
							:pager-count="5"
							layout="total, sizes, prev, pager, next, jumper"
							:total="total"
						>
						</el-pagination>
					</div>
				</div>
			</el-tab-pane>
			<el-tab-pane label="数据详情" name="数据详情">
				<div style="margin-top: 20px" class="formhead">
					<div class="row">
						<el-form-item label="数据类型">
							<el-select v-model="gameType" placeholder="请选择数据类型" style="marginleft: 20px">
								<el-option
									:label="item.label"
									:value="item.value"
									v-for="item in gameList"
									:key="item.value"
								/>
							</el-select>
						</el-form-item>
					</div>
					<div class="btngroup">
						<el-button type="primary" @click="handleDownloadData">下载数据</el-button>
					</div>
				</div>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script setup>
import { onMounted, reactive, ref } from "vue";
import BreadcrumbVue from "@/components/breadcrumb.vue";
import { useRouter } from "vue-router";
import { get, exportExcel } from "../../utils/request";
import dayjs from "dayjs";
import { Decrypt, Encrypt } from "../../utils/secret";
import { ElMessage } from "element-plus";

const router = useRouter();
const activeName = ref("日志详情");
const navigatelist = ref([
	{ key: 1, value: "场次" },
	{ key: 2, value: "场次信息" },
	{ key: 3, value: "查看日志" },
]);
const storeList = ref([]);
const gameList = ref([
	{
		label: "竞标情况",
		value: "1",
	},
	{
		label: "中标结果",
		value: "2",
	},
	{
		label: "玩家物资",
		value: "3",
	},
	{
		label: "玩家道具",
		value: "4",
	},
]);
const page = ref(1);
const size = ref(10);
const total = ref(0);
const storeId = ref(undefined);
const gameType = ref(undefined);
const gameObject = reactive({ data: "" });

onMounted(() => {
	//先解码再解密
	if (router.currentRoute.value.query.gameData) {
		const encodedData = JSON.parse(
			Decrypt(decodeURIComponent(router.currentRoute.value.query.gameData))
		);
		// console.log(encodedData)
		gameObject.data = encodedData;
		console.log(gameObject.data);
	}
	getManageList();
	getlist();
});
const getlist = () => {
	let url = `/manager/game/log?page=${page.value}&size=${size.value}&sessionId=${gameObject.data.id}`;
	if (storeId.value !== undefined) {
		url = url + `&value=${storeId.value}`;
	} else {
		url = url + `&value=`;
	}
	get(url).then((res) => {
		// console.log(res);
		if (res.code == 1) {
			tableData.value = res.result.list;
			total.value = res.result.total;
		}
	});
};
const handleDownload = () => {
	let url = `/manager/game/log/data/download?sessionId=${gameObject.data.id}`;
	if (storeId.value !== undefined) {
		url = url + `&value=${storeId.value}`;
	} else {
		url = url + `&value=`;
	}
	exportExcel(url, "POST", {}, "日志详情" + dayjs(Date.now()).format("YYYY-MM-DD"));
};
const handleDownloadData = () => {
	let url = `/manager/game/log/download?sessionId=${gameObject.data.id}`;
	if (gameType.value !== undefined) {
		url = url + `&value=${gameType.value}`;
	} else {
		ElMessage.error("请选择数据类型");
		return;
	}
	exportExcel(url, "POST", {}, "数据详情" + dayjs(Date.now()).format("YYYY-MM-DD"));
};
const handleReset = () => {
	storeId.value = undefined;
	getlist();
};
const handleSearch = () => {
	getlist();
};
const handleClick = (tab, event) => {
	console.log(tab.props.name);
	if (tab.props.name == "数据详情") {
		navigatelist.value[2].value = "查看详情";
	} else {
		navigatelist.value[2].value = "查看日志";
	}
};
let tableData = ref([]);
const tablecolumn = reactive([
	{
		prop: "time",
		label: "时间",
		width: "180",
	},
	{
		prop: "label",
		label: "游戏阶段",
		width: "180",
	},
	{
		prop: "content",
		label: "操作详情",
		width: "",
	},
]);
const getManageList = () => {
	get(`/manager/game/round`, {}, false).then((res) => {
		if (res.code === 1) {
			let list = res.result;
			list.unshift({
				label: "全部",
				value: "",
			});
			storeList.value = res.result;
		}
	});
};
const handleCurrentChange = (val) => {
	page.value = val;
	getlist();
};
const handleSizeChange = (val) => {
	size.value = val;
	getlist();
};
</script>

<style lang="less" scoped>
.GameLogList {
	width: 98%;
	padding: 10px 10px;
}
.flexbox {
	display: flex;
	align-items: center;
	justify-content: center;
}
.btngroup {
	margin-top: 10px;
	display: flex;
	justify-content: flex-end;
}
.tablewrap {
	margin-top: 10px;
}
.el-input {
	width: 200px;
}
.formhead {
	.row {
		display: flex;
		> div {
			margin-left: 20px;
		}
		> div:first-child {
			margin-left: 0px;
		}
	}
	/deep/ .el-range-editor.el-input__wrapper {
		width: 250px;
	}
}
.paginationwrap {
	display: flex;
	margin-top: 20px;
	margin-bottom: 20px;
	justify-content: flex-end;
}
</style>
