<template>
	<div class="PlayerCount">
		<BreadcrumbVue :navigatelist="navigatelist" />
		<div class="tablewrap">
			<el-table :data="tableData" style="width: 100%" empty-text="还没有数据哦~" stripe>
				<el-table-column
					v-for="(item, index) in tablecolumn"
					:key="index"
					:prop="item.prop"
					:label="item.label"
					:width="item.width"
					:sortable="item.sortable"
				>
					<template #default="scope">
						<div v-if="item.prop == 'integralupdate'">
							<el-input
								v-model="input"
								placeholder="请输入修改后的积分"
								@input="handlechange"
								maxlength="10"
							/>
						</div>
						<div v-else-if="scope.row[item.prop] != 0 && !scope.row[item.prop]">-</div>
						<div v-else>{{ scope.row[item.prop] }}</div>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div class="btn">
			<el-button type="primary" @click="handleupdate">确定</el-button>
			<el-button @click="handlecancel">取消</el-button>
		</div>
	</div>
</template>

<script setup>
import { onMounted, reactive, ref } from "vue";
import BreadcrumbVue from "@/components/breadcrumb.vue";
import { useRouter } from "vue-router";
import { post } from "../../utils/request";
import { ElMessage } from "element-plus";
import { Decrypt } from "../../utils/secret";
const router = useRouter();
let tableData = ref([]);
const navigatelist = [
	{ key: 1, value: "玩家" },
	{ key: 2, value: "玩家信息" },
	{ key: 3, value: "玩家积分" },
];
const input = ref("");
const tablecolumn = reactive([
	{
		prop: "id",
		label: "玩家编号",
		width: "",
	},
	{
		prop: "nickname",
		label: "昵称",
		width: "",
	},
	{
		prop: "grade",
		label: "等级",
		width: "",
	},
	{
		prop: "ranking",
		label: "排名",
		width: "",
	},
	{
		prop: "integral",
		label: "现有积分",
		width: "",
	},
	{
		prop: "integralupdate",
		label: "修改后积分",
		width: "",
	},
]);
onMounted(() => {
	//先解码再解密
	if (router.currentRoute.value.query.list) {
		const encodedData = JSON.parse(
			Decrypt(decodeURIComponent(router.currentRoute.value.query.list))
		);
		console.log(encodedData);
		tableData.value.push(encodedData);
	}
});
const handlecancel = () => {
	router.go(-1);
};
const handleupdate = (item) => {
	if (!input.value) {
		if (!document.querySelector(".el-message")) {
			ElMessage.error("请输入修改后的积分");
		}
		return;
	}
	let params = {
		id: tableData.value[0].id,
		integral: input.value,
	};
	post(`/manager/player/modify`, params).then((res) => {
		if (res.code == 1) {
			ElMessage({
				message: "修改积分成功",
				type: "success",
			});
			router.go(-1);
		} else {
		}
	});
};
const handlechange = (item) => {
	const intValue = parseInt(input.value);
	if (!isNaN(intValue) && intValue >= 0) {
		// 如果是正整数，则更新 v-model 绑定的数据
		input.value = intValue.toString();
	} else {
		// 否则，将数据设置为空字符串，即不更新 v-model 绑定的数据
		input.value = "";
	}
};
</script>

<style lang="less" scoped>
.PlayerCount {
	width: 98%;
	padding: 10px 10px;
}
.flexbox {
	display: flex;
	align-items: center;
	justify-content: center;
}
.tablewrap {
	margin-top: 20px;
}
.btn {
	margin-top: 20px;
}
</style>
