<template>
    <el-breadcrumb separator="/" style="margin-top:10px">
        <el-breadcrumb-item v-for="(item,index) in navigatelist" :key="item.key" @click="navigate(index)" :class="index>0&&index<navigatelist.length-1?'hovertext':''">
            <div :class="index>0&&index<navigatelist.length-1?'hovertext':''">{{item.value}}</div>
        </el-breadcrumb-item>
    </el-breadcrumb>
</template>

<script setup>
import { defineProps } from 'vue';
import { useRouter } from "vue-router"
const router = useRouter()
const props = defineProps(['navigatelist'])
const navigate = (index) =>{
    if(index===0){
        return
    }
    let goback =  index + 1 - props.navigatelist.length
    if(goback<0){
        router.go(goback)
    }
}
</script>
<style lang="less" scoped>
    .hovertext{
        cursor: pointer !important;
    }
    .hovertext :hover{
        color:var(--el-color-primary) !important;
    }
</style>
