<template>
	<div class="PlayerList">
		<BreadcrumbVue :navigatelist="navigatelist" />
		<div style="margin-top: 20px" class="formhead">
			<div class="row">
				<el-form-item label="玩家编号">
					<el-input v-model="id" placeholder="请输入玩家编号" />
				</el-form-item>
				<el-form-item label="玩家创建时间">
					<el-date-picker
						v-model="timeArray"
						type="daterange"
						start-placeholder="开始时间"
						end-placeholder="结束时间"
					/>
				</el-form-item>
				<el-form-item label="玩家状态">
					<el-select v-model="status" placeholder="请选择玩家状态" style="width: 150px">
						<el-option label="全部" value="" />
						<el-option label="普通" :value="0" />
						<el-option label="黑名单" :value="1" />
					</el-select>
				</el-form-item>
				<el-form-item label="玩家等级">
					<el-select v-model="role" placeholder="请选择玩家等级" style="width: 150px">
						<el-option label="全部" value="" />
						<el-option label="普通玩家" :value="0" />
						<el-option label="J-Gamer" :value="1" />
					</el-select>
				</el-form-item>
        <el-form-item label="赛季查询">
          <el-select v-model="seasonId" placeholder="赛季查询" style="width: 150px">
            <el-option
                v-for="item in seasonList"
                :key="item.id"
                :label="item.seasonName"
                :value="item.id"
            />
          </el-select>
        </el-form-item>
			</div>
		</div>
		<div class="btngroup">
      <el-button type="primary" @click="isClearLogin = true">清除登陆</el-button>
			<el-button @click="isClearLogin = false">重置</el-button>
			<el-button type="primary" @click="handleSearch">确认搜索</el-button>
		</div>
		<div class="tablewrap">
			<el-table
				:data="tableData"
				@sort-change="handleSortChange"
				style="width: 100%"
				empty-text="还没有数据哦~"
				stripe
			>
				<el-table-column
					v-for="(item, index) in tablecolumn"
					:key="index"
					:prop="item.prop"
					:label="item.label"
					:width="item.width"
					:sortable="item.sortable"
				>
					<template #default="scope">
						<div v-if="scope.row[item.prop] !== 0 && !scope.row[item.prop]">-</div>
						<div v-else-if="item.prop == 'createTime'">
							{{ dayjs(scope.row[item.prop]).format("YYYY-MM-DD HH:mm:ss") }}
						</div>
						<div
							class="hoverclass"
							v-else-if="item.prop == 'integral'"
							@click="handleupdate(scope.row, 'intergal')"
						>
							{{ scope.row[item.prop] }} >>
						</div>
            <div
                class="hoverclass"
                v-else-if="item.prop == 'jdiceDebrisCount'"
                @click="handleupdate(scope.row, 'jdiceDebrisCount')"
            >
              {{ scope.row[item.prop] }} >>
            </div>
						<div
							class="hoverclass"
							v-else-if="item.prop == 'status'"
							@click="handleupdate(scope.row, 'status')"
						>
							{{ scope.row[item.prop] == 0 ? "普通" : "黑名单" }} >>
						</div>
						<div
							class="hoverclass"
							v-else-if="item.prop == 'role'"
							@click="handleupdate(scope.row, 'status')"
						>
							{{ scope.row[item.prop] == 0 ? "普通" : "J-Gamer" }} >>
						</div>
						<div v-else>{{ scope.row[item.prop] }}</div>
					</template>
				</el-table-column>
				<el-table-column fixed="right" label="详情" width="185">
					<template #default="scope">
						<el-button link type="primary" @click="handleupdate(scope.row, 'detail')"
							>查看 >></el-button
						>
            <el-button link type="primary" @click="handleupdate(scope.row, 'integral')"
            >积分记录 >></el-button
            >
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div class="paginationwrap">
			<el-pagination
				class="paging fr"
				@current-change="handleCurrentChange"
				:current-page="page"
				@size-change="handleSizeChange"
				:page-sizes="[10, 20, 50, 100, 200]"
				:page-size="size"
				:pager-count="5"
				layout="total, sizes, prev, pager, next, jumper"
				:total="total"
			>
			</el-pagination>
		</div>
    <el-dialog
        title="清除玩家缓存"
        v-model="isClearLogin"
        width="30%"
        :before-close="handleClose">
      <span>您确定要清除所有玩家的登陆状态吗?</span>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="isClearLogin = false">取 消</el-button>
          <el-button type="primary" @click="clearLoginClick">确 定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { onMounted, reactive, ref } from "vue";
import BreadcrumbVue from "@/components/breadcrumb.vue";
import { useRouter } from "vue-router";
import { get, post } from "../../utils/request";
import dayjs from "dayjs";
import { Encrypt } from "../../utils/secret";
const router = useRouter();
const navigatelist = [
	{ key: 1, value: "玩家" },
	{ key: 2, value: "玩家信息" },
];
const isClearLogin = ref(false)
const page = ref(1);
const size = ref(10);
const total = ref(0);
const dateSort = ref(0);
const integralSort = ref(0);
const id = ref(undefined);
const timeArray = ref([]);
const status = ref(undefined);
const role = ref(undefined);
const seasonList = ref([]);
const seasonId = ref('');
// const defaultTime = ref<[Date, Date]>([
//   new Date(2000, 1, 1, 0, 0, 0),
//   new Date(2000, 2, 1, 23, 59, 59),
// ])
let tableData = ref([]);
const tablecolumn = reactive([
	{
		prop: "id",
		label: "玩家编号",
		width: "",
	},
	{
		prop: "nickname",
		label: "昵称",
		width: "",
	},
	{
		prop: "integral",
		label: "积分",
		width: "",
		sortable: "custom",
	},
	{
		prop: "grade",
		label: "等级",
		width: "",
	},
	{
		prop: "ranking",
		label: "排名",
		width: "",
	},
  {
    prop: "seasonName",
    label: "赛季名称",
    width: "",
  },
  {
    prop: "seasonPoints",
    label: "赛季积分",
    width: "",
  },
  {
    prop: "seasonRanking",
    label: "赛季排名",
    width: "",
  },
  {
    prop: "propertyCount1",
    label: "悟",
    width: "",
  },
  {
    prop: "propertyCount2",
    label: "谋",
    width: "",
  },
  {
    prop: "propertyCount3",
    label: "联",
    width: "",
  },
  {
    prop: "propertyCount4",
    label: "魅",
    width: "",
  },
  {
    prop: "propertyCount5",
    label: "奇",
    width: "",
  },
  {
    prop: "propertyCount6",
    label: "运",
    width: "",
  },
  {
    prop: "totalCount",
    label: "得票合计",
    width: "",
  },
  {
    prop: "unlockStatus",
    label: "六维解锁状态",
    width: "",
  },
  {
    prop: "jdiceDebrisCount",
    label: "当前碎片",
    width: "",
  },
  {
    prop: "exchangeCount",
    label: "兑换次数",
    width: "",
  },
	{
		prop: "createTime",
		label: "创建时间",
		width: "200",
		sortable: "custom",
	},
	{
		prop: "status",
		label: "状态",
		width: "",
	},
	{
		prop: "role",
		label: "等级",
		width: "",
	},
]);
onMounted(() => {
	getlist();
  getSeasonlist();
});
const getSeasonlist = () => {
  let url = `/admin/seasonManagement/list`;
  get(url).then((res) => {
    console.log("get seasonManagement->",res);
    if (res.code === 1) {
      seasonList.value = res.result;
    }
  });
};
const handleSearch = () => {
	getlist();
};
const handleReset = () => {
	id.value = undefined;
	timeArray.value = [];
	status.value = undefined;
	role.value = undefined;
  seasonId.value = undefined;
	getlist();
};
const handleupdate = (item, type) => {
	let list = encodeURIComponent(Encrypt(JSON.stringify(item)));
	if (type == "intergal") {
		router.push(`/playercount?list=${list}`);
	} else if (type == "status") {
		router.push(`/playerstatus?list=${list}`);
	} else if (type == "detail") {
		router.push(`/playerdetail?list=${list}`);
	}else if (type == "integral") {
    router.push(`../integralRecord?id=${item.id}`);
  }else if(type =='jdiceDebrisCount') {
    router.push(`/PlayerFragments?list=${list}`);
  }else if(type == 'fragmentRecord') {
    router.push(`../fragmentRecord?id=${item.id}`);
  }
};
const getlist = () => {
	let url = `/manager/player/list?page=${page.value}&size=${size.value}`;
	if (timeArray.value.length > 0) {
		url =
			url +
			`&start=${dayjs(timeArray.value[0]).format("YYYY-MM-DD HH:mm:ss")}&end=${dayjs(
				timeArray.value[1]
			).format("YYYY-MM-DD HH:mm:ss")}`;
	}
	if (id.value) {
		url = url + `&id=${id.value}`;
	}
	if (status.value !== undefined) {
		url = url + `&status=${status.value}`;
	}
	if (role.value !== undefined) {
		url = url + `&role=${role.value}`;
	}
  if (seasonId.value !== undefined) {
    url = url + `&seasonId=${seasonId.value + ''}`;
  }
	if (dateSort.value) {
		url = url + `&dateSort=${dateSort.value}`;
	}
	if (integralSort.value) {
		url = url + `&integralSort=${integralSort.value}`;
	}
	get(url).then((res) => {
		if (res.code == 1) {
      res.result.list.forEach(res => {
        for(let item in res.characterDto) {
          res[item] = res.characterDto[item]
        }
      })
			tableData.value = res.result.list;
			total.value = res.result.total;
		}
	});
};
const handleCurrentChange = (val) => {
	page.value = val;
	getlist();
};
const handleSizeChange = (val) => {
	size.value = val;
	getlist();
};
const handleSortChange = ({ column, prop, order }) => {
	dateSort.value = 0;
	integralSort.value = 0;
	if (prop == "createTime") {
		if (order == "ascending") {
			//升序
			dateSort.value = 1;
		} else if (order == "descending") {
			//降序
			dateSort.value = 2;
		} else {
			dateSort.value = 0;
		}
	} else if (prop == "integral") {
		if (order == "ascending") {
			//升序
			integralSort.value = 1;
		} else if (order == "descending") {
			//降序
			integralSort.value = 2;
		} else {
			integralSort.value = 0;
		}
	}
	getlist();
};
// 清除登陆状态
const clearLoginClick = async () => {
  const { code, message } = await post("/auth/remove/all")
  if (code == 0) return this.$message.error(message);
  isClearLogin.value = false
  this.$message({
    message: "清除成功",
    type: "success",
  });
}
</script>

<style lang="less" scoped>
.PlayerList {
	width: 98%;
	padding: 10px 10px;
}
.flexbox {
	display: flex;
	align-items: center;
	justify-content: center;
}
.btngroup {
	margin-top: 10px;
	display: flex;
	justify-content: flex-end;
}
.tablewrap {
	margin-top: 10px;
}
.paginationwrap {
	display: flex;
	margin-top: 20px;
	margin-bottom: 20px;
	justify-content: flex-end;
}
.hoverclass {
	cursor: pointer;
}
.hoverclass:hover {
	color: var(--el-color-primary);
}
.el-input {
	width: 200px;
}
.formhead {
	.row {
		display: flex;
		> div {
			margin-left: 20px;
		}
		> div:first-child {
			margin-left: 0px;
		}
	}
	/deep/ .el-range-editor.el-input__wrapper {
		width: 350px;
	}
}
</style>
