<template>
	<div class="AccountView">
		<BreadcrumbVue :navigatelist="navigatelist" />
		<div class="formpart">
			<el-form :model="form" label-width="120px">
				<el-form-item label="输入密码">
					<el-input
						show-password
						v-model="form.password"
						placeholder="请输入密码"
						type="password"
					/>
				</el-form-item>

				<el-form-item label="再次输入密码">
					<el-input
						show-password
						v-model="form.confirmPassword"
						placeholder="请再次输入密码"
						type="password"
					/>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onSubmit">确定</el-button>
					<el-button @click="oncancel">重置</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script setup>
import { onMounted, reactive, ref } from "vue";
import BreadcrumbVue from "@/components/breadcrumb.vue";
import { post } from "../../utils/request";
import { useRouter } from "vue-router";
import { ElMessage } from "element-plus";
import { oncePress } from "../../utils/limitClick";
import dayjs from "dayjs";
const router = useRouter();
const navigatelist = [
	{ key: 1, value: "系统" },
	{ key: 2, value: "账号相关" },
];
const form = reactive({
	password: "",
	confirmPassword: "",
});
const onSubmit = () => {
	oncePress.resTime = 2000;
	oncePress.onPress(() => {
		let params = {
			password: form.password,
			confirmPassword: form.confirmPassword,
		};
		post("/manager/modify/password", params).then((res) => {
			if (res.code == 1) {
				ElMessage({
					message: "密码修改成功",
					type: "success",
				});
				localStorage.setItem("access_token", "");
				localStorage.setItem("userName", "");
				router.push("/");
			}
		});
	});
};
const oncancel = () => {
	form.password = "";
	form.confirmPassword = "";
};
</script>

<style lang="less" scoped>
.AccountView {
	width: 98%;
	padding: 10px;
}
.formpart {
	margin-top: 30px;
}
.el-input {
	width: 300px;
}
</style>
