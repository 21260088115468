<template>
  <div class="boss letterOfInvitation">
    <BreadcrumbVue :navigatelist="navigatelist" />
    <div class="search">
      <div class="row">
        <el-form-item label="商家名称">
          <el-input v-model="shopName" placeholder="请输入商家名称" />
        </el-form-item>
      </div>
      <div class="btngroup">
        <el-button type="primary" @click="showEditing('add')">新增</el-button>
        <el-button @click="shopName = ''">重置</el-button>
        <el-button type="primary" @click="getList">确认搜索</el-button>
      </div>
    </div>
    <div class="table">
      <el-table
          :data="list"
          style="width: 100%"
          empty-text="还没有数据哦~"
          stripe
      >
        <el-table-column
            v-for="(item, index) in listTitle"
            :key="index"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            :sortable="item.sortable"
        >
          <template #default="scope">
            <div v-if="!scope.row[item.prop]">-</div>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="185">
          <template  #default="scope">
            <el-button link type="primary" @click="showEditing('edit', scope.row)">编辑></el-button>
            <el-button link type="primary" @click="showRecord(scope.row)">查看记录></el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="paginationwrap">
      <el-pagination
          class="paging fr"
          @current-change="handleCurrentChange"
          :current-page="page"
          @size-change="handleSizeChange"
          :page-sizes="[10, 20, 50, 100, 200]"
          :page-size="size"
          :pager-count="5"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
      >
      </el-pagination>
    </div>
    <!--编辑/创建邀请函-->
    <el-dialog
        title="新增"
        v-model="isEditing"
        width="500px"
        :before-close="cancelEditing">
      <el-form :model="editingData" :rules="editingRules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="商家名称" v-if="editingType === 'edit'">
          {{editingData.sroreName}}
        </el-form-item>
        <el-form-item label="商家名称" prop="storeId" v-else>
          <el-select v-model="editingData.storeId" placeholder="请选择商家名称" style="width: 100%">
            <el-option v-for="item in shopList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="交易类型" prop="transactionType">
          <el-select v-model="editingData.transactionType" placeholder="请选择交易类型" style="width: 100%">
            <el-option label="增加" :value="0"></el-option>
            <el-option label="扣减" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="交易数量" prop="stock">
          <el-input v-model="editingData.stock"></el-input>
        </el-form-item>
        <el-form-item label="说明" style="width: 100%"  prop="description">
          <el-input type="textarea" v-model="editingData.description"></el-input>
        </el-form-item>
      </el-form>
      <div class="but">
        <el-button @click="cancelEditing">取 消</el-button>
        <el-button type="primary" @click="editingSubmit">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
        title="查看记录"
        v-model="isRecord"
        width="1000px">
      <div class="tablewrap">
        <el-table
            :data="recordData"
            style="width: 100%"
            empty-text="还没有数据哦~"
            stripe
        >
          <el-table-column
              v-for="(item, index) in recordTitle"
              :key="index"
              :prop="item.prop"
              :label="item.label"
              :width="item.width"
              :sortable="item.sortable"
          >
            <template #default="scope">
              <div v-if="scope.row[item.prop] !== 0 && !scope.row[item.prop]">
                -
              </div>
              <div v-else-if="item.prop == 'transactionType'">
                {{ scope.row[item.prop] === 0 ? '增加' : scope.row[item.prop] === 1 ? '扣减' : '兑换' }}
              </div>
              <div v-else>{{ scope.row[item.prop] }}</div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="paginationwrap">
        <el-pagination
            class="paging fr"
            @current-change="recordHandleCurrentChange"
            :current-page="recordPage"
            @size-change="recordHandleSizeChange"
            :page-sizes="[10, 20, 50, 100, 200]"
            :page-size="recordSize"
            :pager-count="5"
            layout="total, sizes, prev, pager, next, jumper"
            :total="recordTotal"
        >
        </el-pagination>
      </div>
    </el-dialog>
  </div>
</template>
<script setup>
import { reactive, onMounted, ref } from "vue";
import { ElMessage } from "element-plus";
import { useRouter } from "vue-router";
import { Encrypt } from "@/utils/secret";
import BreadcrumbVue from "@/components/breadcrumb.vue";
import dayjs from "dayjs";
import { get, post } from "@/utils/request";
onMounted(() => {
  getList()
  getShopList()
});
const router = useRouter();
const navigatelist = ref([
  { key: 1, value: "特殊道具" },
  { key: 2, value: "J-Dice管理" },
]);
// 表格数据
const listTitle = reactive([
  { label: "商家编号", prop: "storeId", width: "" },
  { label: "商家名称", prop: "sroreName", width: "" },
  { label: "累计兑换数", prop: "totalExchangeCount", width: "" },
  { label: "累计扣减数", prop: "totalDeductionCount", width: "" },
  { label: "库存数", prop: "stock", width: "" },
  { label: "累计增加数", prop: "totalIncreaseCount", width: "" },
])
const list = ref([])
const shopName = ref("")
const getList = async () => {
  let url = `/admin/j_dice/page?current=${page.value}&size=${size.value}`
  if (shopName.value) url += `&sroreName=${shopName.value}`
  const { result } = await get(url)
  list.value = result.records
  total.value = result.total
}
// 翻页数据
const page = ref(1);
const size = ref(10);
const total = ref(0);
const handleCurrentChange = (val) => {
  page.value = val;
  getList()
}
const handleSizeChange = (val) => {
  size.value = val;
  getList();
}
// 编辑/创建商家
const isEditing = ref(false)
const editingType = ref("")
const editingRules = reactive({
  storeId: [
    { required: true, message: "请选择商家名称", trigger: "blur" },
  ],
  stock: [
    { required: true, message: "请输入库存", trigger: "blur" },
  ],
  transactionType: [
    { required: true, message: "请选择交易类型", trigger: "blur" },
  ],
  description: [
    { required: true, message: "请输入说明", trigger: "blur" },
  ],
})
const editingData = reactive({
  storeId: "",
  stock: "",
  transactionType: "",
  description: "",
  sroreName: "",
  id: "",
})
const showEditing = (v, v1) => {
  editingType.value = v
  if (v === "edit") {
    editingData.sroreName = v1.sroreName
    editingData.storeId = v1.storeId
    editingData.id = v1.id
  }
  isEditing.value = true
}
// 取消编辑
const cancelEditing = () => {
  isEditing.value = false
  for (let item in editingData) {
    editingData[item] = ""
  }
}
// 提交编辑
const editingSubmit = async () => {
  let url = ""
  if (editingType.value === "add") {
    url = "/admin/j_dice/createSave"
  } else {
    url = "/admin/j_dice/updateDice"
    url += `?storeId=${editingData.storeId}&stock=${editingData.stock}&transactionType=${editingData.transactionType}&description=${editingData.description}&id=${editingData.id}`
  }
  const { code, message } = await post(url,editingType.value === "add"?{
    storeId: editingData.storeId,
    stock: editingData.stock,
    transactionType: editingData.transactionType,
    description: editingData.description,
  }:{})
  if (code !== 1) return ElMessage.error(message)
  getList()
  isEditing.value = false
  for (let item in editingData) {
    editingData[item] = ""
  }
}
// 查看记录
const isRecord = ref(false)
const recordPage = ref(1);
const recordSize = ref(10);
const recordTotal = ref(0);
const recordData = ref([])
const recordId = ref("")
const recordTitle = reactive([
  { label: "交易时间", prop: "transactionTime", width: "" },
  { label: "交易类型", prop: "transactionType", width: "" },
  { label: "数量", prop: "quantity", width: "" },
  { label: "库存数", prop: "stockCount", width: "" },
  { label: "操作人", prop: "operator", width: "" },
  { label: "说明", prop: "description", width: "" },
])
const showRecord = (val) => {
  recordId.value = val.id
  isRecord.value = true
  recordList()
}
const recordHandleCurrentChange = (val) => {
  recordPage.value = val;
  recordList()
}
const recordHandleSizeChange = (val) => {
  recordSize.value = val;
  recordList();
}
const recordList = async () => {
  const { result } = await get(`/admin/j_dice_record/page?current=${recordPage.value}&size=${recordSize.value}&jdiceId=${recordId.value}`)
  recordData.value = result.records;
  recordTotal.value = result.total
}
// 获取商家
const shopList = ref([])
const getShopList = () => {
  get(`/manager/store/list?page=1&size=10000`).then((res) => {
    if (res.code === 1) {
      shopList.value = res.result.list;
    }
  });
}
</script>
<style lang="less" scoped>
.boss{
  padding: 20px;
  width: 100%;
  .search{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
    .btngroup{
      margin-left: 20px;
    }
  }
  .paginationwrap {
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    justify-content: flex-end;
  }
  .demo-ruleForm{
    //display: flex;
    //flex-wrap: wrap;
    padding-right: 40px;
    .el-form-item{
      width: 100%;
    }
  }
  .but{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-right: 40px;
  }
}
</style>
