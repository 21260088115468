<template>
	<div class="OperatorView">
		<BreadcrumbVue :navigatelist="navigatelist" />
		<div class="btngroup">
			<div @click="handleRefresh">
				<el-icon :size="20">
					<Refresh style="width: 100%; height: 100%" />
				</el-icon>
				刷新
			</div>
		</div>
		<div class="tablewrap">
			<el-table :data="tableData" style="width: 100%" empty-text="还没有数据哦~" stripe>
				<el-table-column
					v-for="(item, index) in tablecolumn"
					:key="index"
					:prop="item.prop"
					:label="item.label"
					:width="item.width"
					:sortable="item.sortable"
				>
					<template #default="scope">
						<div v-if="scope.row[item.prop] !== 0 && !scope.row[item.prop]">-</div>
						<div v-else-if="item.prop == 'createTime'">
							{{ dayjs(scope.row[item.prop]).format("YYYY-MM-DD HH:mm:ss") }}
						</div>
						<div
							v-else-if="item.prop == 'hostCount'"
							class="hoverclass"
							@click="toEmceeList(scope.row)"
						>
							{{ scope.row[item.prop] }} >>
						</div>
						<div v-else>{{ scope.row[item.prop] }}</div>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div class="paginationwrap">
			<el-pagination
				class="paging fr"
				@current-change="handleCurrentChange"
				:current-page="page"
				@size-change="handleSizeChange"
				:page-sizes="[10, 20, 50, 100, 200]"
				:page-size="size"
				:pager-count="5"
				layout="total, sizes, prev, pager, next, jumper"
				:total="total"
			>
			</el-pagination>
		</div>
	</div>
</template>

<script setup>
import { onMounted, reactive, ref } from "vue";
import BreadcrumbVue from "@/components/breadcrumb.vue";
import { get } from "../../utils/request";
import { useRouter } from "vue-router";
import dayjs from "dayjs";
import { oncePress } from "../../utils/limitClick";
const router = useRouter();
const navigatelist = [
	{ key: 1, value: "系统" },
	{ key: 2, value: "操作记录" },
];
const page = ref(1);
const size = ref(10);
const total = ref(0);
let tableData = ref([]);
const tablecolumn = reactive([
	{
		prop: "createTime",
		label: "操作时间",
		width: "200",
	},
	{
		prop: "username",
		label: "操作用户名",
		width: "150",
	},
	{
		prop: "operationContent",
		label: "操作内容",
		width: "",
	},
]);
onMounted(() => {
	getlist();
});
const getlist = () => {
	let url = `/manager/operation/record/list?page=${page.value}&size=${size.value}`;
	get(url).then((res) => {
		if (res.code === 1) {
			tableData.value = res.result.list;
			total.value = res.result.total;
		}
	});
};
const handleCurrentChange = (val) => {
	page.value = val;
	getlist();
};
const handleSizeChange = (val) => {
	size.value = val;
	getlist();
};
const handleRefresh = () => {
	oncePress.onPress(() => {
		page.value = 1;
		size.value = 10;
		getlist();
	});
};
</script>

<style lang="less" scoped>
.OperatorView {
	width: 98%;
	padding: 10px;
	.btngroup {
		margin-top: 10px;
		padding: 0px 10px;
		display: flex;
		justify-content: flex-end;
		> div {
			cursor: pointer;
			display: flex;
			align-items: center;
		}
		> div:hover {
			color: var(--el-color-primary);
		}
	}
	.tablewrap {
		margin-top: 10px;
	}
	.paginationwrap {
		display: flex;
		margin-top: 20px;
		margin-bottom: 20px;
		justify-content: flex-end;
	}
}
</style>
