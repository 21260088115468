export const oncePress = {
  lastPressTime: 1,  //  上次点击时间
  resTime: 1000,   //  间隔时间
  onPress(callback:any) {
      let curTime = Date.now();
      if (curTime - this.lastPressTime > this.resTime) {
          this.lastPressTime = curTime;
          this.resTime = 1000;  //  这里的时间和上面的匹配
          callback();
      }
  },
};