<template>
	<div class="gamepointchange">
		<BreadcrumbVue :navigatelist="navigatelist" />
		<div class="tablewrap">
			<el-table
				:data="tableData"
				@sort-change="handleSortChange"
				style="width: 100%"
				empty-text="还没有数据哦~"
				stripe
			>
				<el-table-column
					v-for="(item, index) in tablecolumn"
					:key="index"
					:prop="item.prop"
					:label="item.label"
					:width="item.width"
					:sortable="item.sortable"
				>
					<template #default="scope">
						<div v-if="item.prop == 'updatepoint'">
							<el-input v-model="input" placeholder="请输入修改后点数" @input="handlechange" />
						</div>
            <div v-if="item.prop == 'playCount'">
              <el-input v-model="playCountValue" placeholder="请输入修改后游玩次数" @input="handlechange" />
            </div>
						<div v-else>{{ scope.row[item.prop] }}</div>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div class="btn">
			<el-button type="primary" @click="handleupdate">确定</el-button>
			<el-button @click="handlecancel">取消</el-button>
		</div>
	</div>
</template>

<script setup>
import { onMounted, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import { post } from "../../utils/request";
import BreadcrumbVue from "@/components/breadcrumb.vue";
import { ElMessage } from "element-plus";
import { Decrypt } from "../../utils/secret";
const navigatelist = [
	{ key: 1, value: "商家" },
	{ key: 2, value: "游戏点数" },
	{ key: 3, value: "修改点数" },
];
const router = useRouter();
const tablecolumn = reactive([
	{
		prop: "id",
		label: "商家编号",
		width: 100,
	},
	{
		prop: "name",
		label: "商家名称",
		width: "",
	},
	{
		prop: "gameName",
		label: "游戏名称",
		width: "",
	},
	{
		prop: "gamePoint",
		label: "游戏当前点数",
		width: "",
	},
  {
    prop: "playCount",
    label: "修改后的试玩次数",
    width: "",
  },
	{
		prop: "updatepoint",
		label: "修改后的点数",
		width: "",
	},
]);
let tableData = ref([]);
const input = ref("");
const playCountValue = ref("")
onMounted(() => {
	// 先解码再解密
	if (router.currentRoute.value.query.list) {
		const encodedData = JSON.parse(
			Decrypt(decodeURIComponent(router.currentRoute.value.query.list))
		);
    console.log(encodedData)
    input.value = encodedData.gamePoint
    playCountValue.value = encodedData.playCount || 0
		tableData.value.push(encodedData);
	}
});
const handlecancel = () => {
	router.go(-1);
};
const handleupdate = (item) => {
	if (!input.value && !playCountValue.value) {
		if (!document.querySelector(".el-message")) {
			ElMessage.error("请输入修改后的游戏点数或修改后的试玩次数");
		}
		return;
	}
	let params = {
		id: tableData.value[0].id,
		storeId: tableData.value[0].id,
		point: input.value,
    playCount: playCountValue.value,
	};
	post("/manager/game/point", params).then((res) => {
		if (res.code == 1) {
			ElMessage({
				message: "修改点数成功",
				type: "success",
			});
			router.go(-1);
		} else {
		}
	});
};
const handlechange = (item) => {
	const intValue = parseInt(input.value);
	if (!isNaN(intValue) && intValue >= 0) {
		// 如果是正整数，则更新 v-model 绑定的数据
		input.value = intValue.toString();
	} else {
		// 否则，将数据设置为空字符串，即不更新 v-model 绑定的数据
		input.value = "";
	}
};
</script>

<style lang="less" scoped>
.gamepointchange {
	width: 98%;
	padding: 10px;
	.tablewrap {
		margin-top: 20px;
	}
	.btn {
		margin-top: 20px;
	}
}
</style>
