<template>
	<div class="point">
		<BreadcrumbVue :navigatelist="navigatelist" />
<!--    <div class="btngroup">-->
<!--      <el-button type="primary" @click="goAddShop">新增游戏</el-button>-->
<!--    </div>-->
		<div class="tablewrap">
			<el-table :data="tableData.list" style="width: 100%" empty-text="还没有数据哦~" stripe>
				<el-table-column
					v-for="(item, index) in tablecolumn"
					:key="index"
					:prop="item.prop"
					:label="item.label"
					:width="item.width"
					:sortable="item.sortable"
				>
					<template #default="scope">
						<div v-if="scope.row[item.prop] !== 0 && !scope.row[item.prop]">-</div>
						<div v-else-if="item.prop == 'updateTime'">
							{{ dayjs(scope.row[item.prop]).format("YYYY-MM-DD HH:mm:ss") }}
						</div>
						<div v-else-if="item.prop == 'unlockedPlayPoint'">
							<div v-if="scope.row.edit">
								<el-input
									v-model="unlockedPlayPoint"
									placeholder="请输入修改后点数"
									@input="handlechange('unlock')"
								/>
							</div>
							<div v-else>{{ scope.row.unlockedPlayPoint }}</div>
						</div>
						<div v-else-if="item.prop == 'playPoint'">
							<div v-if="scope.row.edit">
								<el-input
									v-model="playPoint"
									placeholder="请输入修改后点数"
									@input="handlechange('change')"
								/>
							</div>
							<div v-else>{{ scope.row.playPoint }}</div>
						</div>
						<div v-else>{{ scope.row[item.prop] }}</div>
					</template>
				</el-table-column>
				<el-table-column fixed="right" label="操作" width="185">
					<template #default="scope">
						<el-button
							link
							type="primary"
							@click="handleupdate('edit', scope.row)"
							v-if="!scope.row.edit"
							>编辑</el-button
						>
						<el-button
							link
							type="primary"
							@click="handleupdate('save', scope.row)"
							v-if="scope.row.edit"
							>保存</el-button
						>
						<el-button
							link
							type="primary"
							@click="handleupdate('cancel', scope.row)"
							v-if="scope.row.edit"
							>取消</el-button
						>
					</template>
				</el-table-column>
			</el-table>
		</div>
	</div>
</template>

<script setup>
import { onMounted, reactive, ref } from "vue";
import BreadcrumbVue from "@/components/breadcrumb.vue";
import { get, post } from "../../utils/request";
import dayjs from "dayjs";
import { getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
const { proxy } = getCurrentInstance();
const navigatelist = [
	{ key: 1, value: "游戏" },
	{ key: 2, value: "游戏设置" },
];
const unlockedPlayPoint = ref("");
const playPoint = ref("");
const id = ref("");
let tableData = reactive({
	list: [],
});
const tablecolumn = reactive([
	{
		prop: "name",
		label: "游戏名称",
		width: "",
	},
	{
		prop: "unlockedPlayPoint",
		label: "新解锁玩家点数",
		width: "",
	},
	{
		prop: "playPoint",
		label: "已解锁玩家点数",
		width: "",
	},
	{
		prop: "updateTime",
		label: "更新时间",
		width: "",
	},
]);
const getlist = () => {
	// tableData.list = [
	// 	{
	// 		id: "2",
	// 		name: "叠罗汉",
	// 		unlockedPlayPoint: 5,
	// 		playPoint: 4,
	// 		updateTime: 1698032352000,
	// 	},
	// 	{
	// 		id: "5",
	// 		name: "生存法则",
	// 		unlockedPlayPoint: 2,
	// 		playPoint: 1,
	// 		updateTime: 1698032352000,
	// 	},
	// ];
	// tableData.list.map((item) => {
	// 	item.edit = false;
	// });
	// return;
	let url = `/manager/game/list`;
	get(url).then((res) => {
		if (res.code === 1) {
			tableData.list = res.result;
			tableData.list.map((item) => {
				item.edit = false;
			});
		}
	});
};
onMounted(() => {
	getlist();
});
const handleupdate = (type, data) => {
	if (type == "edit") {
		tableData.list.map((item) => {
			if (item.id == data.id) {
				item.edit = true;
				unlockedPlayPoint.value = item.unlockedPlayPoint;
				playPoint.value = item.playPoint;
				id.value = item.id;
			} else {
				item.edit = false;
			}
		});
	} else if (type == "save") {
		save();
	} else if (type == "cancel") {
		reset();
	}
};
const save = () => {
	if (!unlockedPlayPoint.value) {
		proxy.message({
			message: "请输入新解锁玩家点数",
			grouping: true,
			type: "error",
		});
		return;
	}
	if (!playPoint.value) {
		proxy.message({
			message: "请输入已解锁玩家点数",
			grouping: true,
			type: "error",
		});
		return;
	}
	let submitData = {
		id: id.value,
		unlockedPlayPoint: unlockedPlayPoint.value,
		playPoint: playPoint.value,
	};
	post(`/manager/game/point/setting`, submitData).then((res) => {
		if (res.code == 1) {
			proxy.message({
				message: "修改成功",
				grouping: true,
				type: "success",
			});
			reset();
			getlist();
		} else {
		}
	});
};
const reset = () => {
	tableData.list.map((item) => {
		item.edit = false;
	});
	unlockedPlayPoint.value = "";
	playPoint.value = "";
	id.value = "";
};
const handlechange = (type) => {
	if (type == "unlock") {
		const input = unlockedPlayPoint.value;
		if (!/^\d+$/.test(input)) {
			unlockedPlayPoint.value = "";
		} else {
			const num = parseInt(input, 10);
			if (num < 0 || num > 99) {
				unlockedPlayPoint.value = "";
			}
		}
	} else {
		const input = playPoint.value;
		if (!/^\d+$/.test(input)) {
			playPoint.value = "";
		} else {
			const num = parseInt(input, 10);
			if (num < 0 || num > 99) {
				playPoint.value = "";
			}
		}
	}
};
const goAddShop = () => {
  router.push("/pointadd");
}
</script>

<style lang="less" scoped>
.point {
	padding: 10px;
	width: 98%;
	.tablewrap {
		margin-top: 10px;
	}
  .btngroup {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
  }
	.paginationwrap {
		display: flex;
		margin-top: 20px;
		margin-bottom: 20px;
		justify-content: flex-end;
	}
	.hoverclass {
		cursor: pointer;
	}
	.hoverclass:hover {
		color: var(--el-color-primary);
	}
}
</style>
