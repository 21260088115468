<template>
  <div class="boss">
    <BreadcrumbVue :navigatelist="navigatelist" />
    <div class="table">
      <el-table
          :data="list"
          style="width: 100%"
          empty-text="还没有数据哦~"
          stripe
      >
        <el-table-column
            v-for="(item, index) in listTitle"
            :key="index"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            :sortable="item.sortable"
        ></el-table-column>
      </el-table>
    </div>
    <div class="paginationwrap">
      <el-pagination
          class="paging fr"
          @current-change="handleCurrentChange"
          :current-page="page"
          @size-change="handleSizeChange"
          :page-sizes="[10, 20, 50, 100, 200]"
          :page-size="size"
          :pager-count="5"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script setup>
import { reactive, onMounted, ref } from "vue";
import { ElMessage } from "element-plus";
import { useRouter } from "vue-router";
import BreadcrumbVue from "@/components/breadcrumb.vue";
import { Decrypt } from "@/utils/secret";
import {get} from "@/utils/request";
const router = useRouter();
let parentLevelData = reactive({})
let navigatelist = ref([])
let url = ref("")
onMounted(() => {
  if (router.currentRoute.value.query.data) {
    const encodedData = JSON.parse(
        Decrypt(decodeURIComponent(router.currentRoute.value.query.data))
    );
    parentLevelData = encodedData;
    navigatelist.value = [
      { key: 1, value: "特殊道具" },
      { key: 2, value: "邀请函管理" },
      { key: 3, value: parentLevelData.label },
    ]
    if (parentLevelData.prop === "totalQuantity") {
      listTitle.value = distribution
      url.value = "/admin/t_grant_record/page"
    } else if (parentLevelData.prop === "giftedQuantity") {
      listTitle.value = giveAway
      url.value = "/admin/t_give_record/page"
    } else if (parentLevelData.prop === "usedQuantity") {
      listTitle.value = useThe
      url.value = "/admin/TactivateRecord/page"
    }
    getList()
  }
});
// 表格数据
const listTitle = ref([])
const list = ref([])
const distribution = reactive([
    { label: "发放时间", prop: "issuanceTime", width: "" },
    { label: "操作人", prop: "operator", width: "" },
    { label: "发放数量", prop: "quantityGranted", width: "" },
    { label: "邀请函名称", prop: "invitationName", width: "" },
])
const giveAway = reactive([
  { label: "赠送时间", prop: "usageTime", width: "" },
  { label: "送出玩家", prop: "giverPlayerName", width: "" },
  { label: "邀请函名称", prop: "invitationName", width: "" },
  { label: "接收玩家", prop: "receiverPlayerName", width: "" },
])
const useThe = reactive([
  { label: "使用时间", prop: "usageTime", width: "" },
  { label: "玩家昵称", prop: "playerName", width: "" },
  { label: "邀请涵名称", prop: "invitationName", width: "" },
  { label: "商家名称", prop: "storeName", width: "" },
])
const getList = async () => {
  const { result } = await get(`${url.value}?current=${page.value}&size=${size.value}&invitationId=${parentLevelData.id}`)
  list.value = result.records
  total.value = result.total
}
// 翻页数据
const page = ref(1);
const size = ref(10);
const total = ref(0);
const handleCurrentChange = (val) => {
  page.value = val;
  getList()
}
const handleSizeChange = (val) => {
  size.value = val;
  getList();
}
</script>
<style lang="less" scoped>
.boss{
  width: 100%;
  padding: 20px;
  .table{
    margin-top: 20px;
  }
  .paginationwrap {
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    justify-content: flex-end;
  }
}
</style>
