<template>
	<div class="top_head_plate">
		<img src="../../assets/logo.png" alt="" style="width: 68px; height: 26px" />

		<div class="top_head_right">
			<p class="top_head_username">{{ userData.name }}</p>

			<el-button type="primary" @click="outLogin">登出</el-button>
		</div>
	</div>
	<el-dialog
		v-model="centerDialogVisible"
		title="提示"
		width="30%"
		:close-on-click-modal="false"
		:close-on-press-escape="false"
	>
		<span> 是否退出登录 </span>
		<template #footer>
			<span class="dialog-footer">
				<el-button @click="centerDialogVisible = false">取消</el-button>
				<el-button type="primary" @click="loginout"> 确定 </el-button>
			</span>
		</template>
	</el-dialog>
</template>

<script setup>
import { reactive, ref } from "vue";
import { post } from "../../utils/request";
import { ElMessage } from "element-plus";
import { useRouter } from "vue-router";

const router = useRouter();

let getUserName = localStorage.getItem("userName");

const userData = reactive({ name: getUserName });

const outMessage = (message) => {
	ElMessage({
		message: "退出登陆成功",
		grouping: true,
		type: "success",
	});
};
const centerDialogVisible = ref(false);
const outLogin = () => {
	centerDialogVisible.value = true;
};
const loginout = () => {
	centerDialogVisible.value = false;
	let getAccessToken = localStorage.access_token;
	post(`/auth/logout?accessToken=${getAccessToken}`).then((res) => {
		// console.log(res)
		if (res.code === 1) {
			outMessage(res.message);
			localStorage.setItem("access_token", "");
			localStorage.setItem("userName", "");
			router.push("/");
		}
	});
};
</script>

<style lang="less" scoped>
.top_head_plate {
	padding: 30px 15px;
	background: #ffffff;
	border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
	display: flex;
	justify-content: space-between;
	align-items: center;

	.top_head_right {
		display: flex;
		justify-content: flex-start;
		align-items: center;

		.top_head_username {
			font-size: 16px;
			color: #292929;
			margin-right: 20px;
		}
	}
}
</style>
