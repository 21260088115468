<template>
	<div class="shoplist">
		<BreadcrumbVue :navigatelist="navigatelist" />
		<div class="btngroup">
			<el-button type="primary" @click="editDetail">新增主持人</el-button>
		</div>
		<div class="tablewrap">
			<el-table :data="tableData" style="width: 100%" empty-text="还没有数据哦~" stripe>
				<el-table-column
					v-for="(item, index) in tablecolumn"
					:key="index"
					:prop="item.prop"
					:label="item.label"
					:width="item.width"
					:sortable="item.sortable"
				>
					<template #default="scope">
						<div v-if="scope.row[item.prop] !== 0 && !scope.row[item.prop]">-</div>
						<div v-else-if="item.prop == 'gameRecentlyStartTime'">
							{{ dayjs(scope.row[item.prop]).format("YYYY-MM-DD HH:mm:ss") }}
						</div>
						<div v-else>{{ scope.row[item.prop] }}</div>
					</template>
				</el-table-column>
				<el-table-column fixed="right" label="操作" width="185">
					<template #default="scope">
						<el-button link type="primary" @click="editDetail(scope.row)">管理</el-button>
						<el-button link type="primary" @click="openMessageBox(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div class="paginationwrap">
			<el-pagination
				class="paging fr"
				@current-change="handleCurrentChange"
				:current-page="page"
				@size-change="handleSizeChange"
				:page-sizes="[10, 20, 50, 100, 200]"
				:page-size="size"
				:pager-count="5"
				layout="total, sizes, prev, pager, next, jumper"
				:total="total"
			>
			</el-pagination>
		</div>
	</div>
</template>

<script setup>
import { onMounted, reactive, ref } from "vue";
import dayjs from "dayjs";
import BreadcrumbVue from "@/components/breadcrumb.vue";
import { get, post } from "../../utils/request";
import { ElMessage, ElMessageBox } from "element-plus";
import { useRouter } from "vue-router";
import { Encrypt } from "../../utils/secret";
const router = useRouter();

const navigatelist = [
	{ key: 1, value: "商家" },
	{ key: 2, value: "商家信息" },
	{ key: 3, value: "主持人" },
];
const page = ref(1);
const size = ref(10);
const total = ref(0);
let tableData = ref([]);
let getStoreId = router.currentRoute.value.query.id;
const storeId = reactive({ id: getStoreId });
const tablecolumn = reactive([
	{
		prop: "id",
		label: "编号",
		width: "",
	},
	{
		prop: "nickname",
		label: "名称",
		width: "",
	},
	{
		prop: "username",
		label: "登录账号",
		width: "",
	},
	{
		prop: "notes",
		label: "备注",
		width: "",
	},
]);
onMounted(() => {
	getlist();
});
const addshop = () => {};
const getlist = () => {
	get(`/manager/host/list?page=${page.value}&size=${size.value}&id=${storeId.id}`).then((res) => {
		if (res.code === 1) {
			tableData.value = res.result.list;
			total.value = res.result.total;
		}
	});
};
const handleCurrentChange = (val) => {
	page.value = val;
	getlist();
};
const handleSizeChange = (val) => {
	size.value = val;
	getlist();
};
const editDetail = (data) => {
	let editData;
	if (data) {
		editData = JSON.stringify({
			id: data.id,
			storeId: storeId.id,
			nickname: data.nickname,
			password: data.password,
			username: data.username,
			notes: data.notes,
		});
	} else {
		editData = null;
	}
	let list = encodeURIComponent(Encrypt(editData));
	router.push("/editEmcee?emceeData=" + list);
};
const openMessageBox = (data) => {
	ElMessageBox.confirm("是否删除该主持人？删除后无法登陆主持人端。", "提示", {
		confirmButtonText: "删除",
		cancelButtonText: "取消",
		type: "none",
		center: true,
	})
		.then(() => deleteEmcee(data))
		.catch(() => {});
};
const open = () => {
	ElMessage({
		message: "删除成功",
		grouping: true,
		type: "success",
	});
};
const deleteEmcee = (data) => {
	post(`/manager/host/del?storeId=${storeId.id}&userId=${data.id}`).then((res) => {
		// console.log(res)
		if (res.code === 1) {
			open();
			page.value = 1;
			getlist();
		}
	});
};
</script>
<style lang="less" scoped>
.shoplist {
	width: 98%;
	padding: 10px;
	.btngroup {
		margin-top: 10px;
		display: flex;
		justify-content: flex-end;
	}
	.tablewrap {
		margin-top: 10px;
	}
	.paginationwrap {
		display: flex;
		margin-top: 20px;
		margin-bottom: 20px;
		justify-content: flex-end;
	}
}
</style>
