var CryptoJS = require("crypto-js");
// Encrypt
export const Encrypt=(data:any)=>{
    if(typeof data === 'string'){
        var ciphertext = CryptoJS.AES.encrypt(data, 'secret key 123').toString();
        return ciphertext
    }else if(typeof data === 'object'){
        var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), 'secret key 123').toString();
        return ciphertext
    }
}
// Decrypt
export const Decrypt=(data:any)=>{
    if(typeof data === 'string'){
        var bytes  = CryptoJS.AES.decrypt(data, 'secret key 123');
        var originalText = bytes.toString(CryptoJS.enc.Utf8);
        return originalText
    }else if(typeof data === 'object'){
        var bytes  = CryptoJS.AES.decrypt(data, 'secret key 123');
        var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return decryptedData
    }
}
