<template>
	<div class="gamepointlist">
		<BreadcrumbVue :navigatelist="navigatelist" />
<!--		<div style="margin-top: 20px">-->
<!--			<el-form-item label="游戏名称">-->
<!--				<el-select v-model="gameid" placeholder="请选择游戏" style="marginleft: 20px">-->
<!--					<el-option-->
<!--						v-for="item in gamelist"-->
<!--						:key="item.id"-->
<!--						:label="item.name"-->
<!--						:value="Number(item.id)"-->
<!--					/>-->
<!--				</el-select>-->
<!--			</el-form-item>-->
<!--		</div>-->
		<div class="btngroup">
			<el-button type="primary" @click="handleSearch">确认搜索</el-button>
		</div>
		<div class="tablewrap">
			<el-table
				:data="tableData"
				@sort-change="handleSortChange"
				style="width: 100%"
				empty-text="还没有数据哦~"
				stripe
			>
				<el-table-column
					v-for="(item, index) in tablecolumn"
					:key="index"
					:prop="item.prop"
					:label="item.label"
					:width="item.width"
					:sortable="item.sortable"
				>
					<template #default="scope">
            <div @click="handleNavigate(scope.row)" v-if="scope.row[item.prop] !== 0 && !scope.row[item.prop] && item.prop == 'playCount'">0 >></div>
						<div v-else-if="scope.row[item.prop] !== 0 && !scope.row[item.prop]">-</div>
						<div
							class="hoverclass"
							v-else-if="item.prop == 'gamePoint' || item.prop == 'accumulatedPoint'|| item.prop == 'playCount'"
							@click="handleNavigate(scope.row)"
						>
							{{ scope.row[item.prop] }} >>
						</div>
						<div v-else>{{ scope.row[item.prop] }}</div>
					</template>
				</el-table-column>
        <el-table-column fixed="right" label="可游玩游戏" width="200">
          <template #default="scope">
            <div style="margin-top: 20px">
            </div>
            		<div style="margin-top: 20px">
            				<el-select v-model="scope.row.gameIds"
                               placeholder="请选择游戏"
                               multiple
                               collapse-tags
                               @visibleChange="gamelistVisibleChange($event,scope.row)"
                    >
            					<el-option
            						v-for="item in gamelist"
            						:key="item.id"
            						:label="item.name"
            						:value="Number(item.id)"
            					/>
            				</el-select>
            		</div>
          </template>
        </el-table-column>
			</el-table>
		</div>
		<div class="paginationwrap">
			<el-pagination
				class="paging fr"
				@current-change="handleCurrentChange"
				:current-page="page"
				@size-change="handleSizeChange"
				:page-sizes="[10, 20, 50, 100, 200]"
				:page-size="size"
				:pager-count="5"
				layout="total, sizes, prev, pager, next, jumper"
				:total="total"
			>
			</el-pagination>
		</div>
	</div>
</template>

<script setup>
import { onBeforeMount, onMounted, reactive, ref } from "vue";
import BreadcrumbVue from "@/components/breadcrumb.vue";
import { get, post } from "../../utils/request";
import { useRouter } from "vue-router";
import { Encrypt } from "../../utils/secret";
const router = useRouter();
const page = ref(1);
const size = ref(10);
const total = ref(0);
const gameid = ref(1);
const rowGameId = ref([]);
const navigatelist = [
	{ key: 1, value: "商家" },
	{ key: 2, value: "游戏点数" },
];
const pointSort = ref(0);
const accumulatePointSort = ref(0);
const tableData = ref([]);
const tablecolumn = reactive([
	{
		prop: "id",
		label: "商家编号",
		width: 100,
	},
	{
		prop: "name",
		label: "商家名称",
		width: "",
	},
  {
    prop: "playCount",
    label: "试玩次数",
    width: "",
  },
	{
		prop: "gamePoint",
		label: "当前游戏点数",
		width: "",
		sortable: "custom",
	},
	{
		prop: "accumulatedPoint",
		label: "累计游戏点数",
		width: "",
		sortable: "custom",
	},
]);
const gamelist = ref([]);

onMounted(() => {
  getGameList();
	getlist();
});
const getGameList = () => {
	get("/manager/game/list", {}, false).then((res) => {
		if (res.code === 1) {
			gamelist.value = res.result;
		}
	});
};
const getlist = () => {
	let url = `/manager/game/store/point/list?page=${page.value}&size=${size.value}&id=${gameid.value}`;
	if (pointSort.value) {
		url = url + `&pointSort=${pointSort.value}`;
	}
	if (accumulatePointSort.value) {
		url = url + `&accumulatePointSort=${accumulatePointSort.value}`;
	}
	get(url).then((res) => {
		if (res.code === 1) {
			tableData.value = res.result.list;
			total.value = res.result.total;
		}
	});
};
const handleSearch = () => {
	getlist();
};
const handleSortChange = ({ column, prop, order }) => {
  pointSort.value = 0;
	accumulatePointSort.value = 0;
	if (prop === "gamePoint") {
		if (order === "ascending") {
			// 升序
			pointSort.value = 1;
		} else if (order === "descending") {
			// 降序
			pointSort.value = 2;
		} else {
			pointSort.value = 0;
		}
	} else if (prop === "accumulatedPoint") {
		if (order === "ascending") {
			// 升序
			accumulatePointSort.value = 1;
		} else if (order === "descending") {
			// 降序
			accumulatePointSort.value = 2;
		} else {
			accumulatePointSort.value = 0;
		}
	}
	getlist();
};
const handleCurrentChange = (val) => {
	page.value = val;
	getlist();
};
const handleSizeChange = (val) => {
	size.value = val;
	getlist();
};
const handleNavigate = (item) => {
	// 先加密再加码
	const list = encodeURIComponent(Encrypt(JSON.stringify(item)));
	router.push(`/gamepointChange?list=${list}`);
};

// 选择游戏后，关闭下拉框时触发
const gamelistVisibleChange = (val, row) => {
  // 如果val为false，说明下拉框关闭
  console.log(123)
  if (!val) {
    post("/manager/game/point/update", {
      storeId: row.id, // 行id
      gameIds: row.gameIds, // 选择的游戏id数组
    }).then((res) => {
      if (res.code === 1) {
        // 请求成功，重新获取列表
        page.value = 1;
        getlist();
      }
    });
  }
};

</script>

<style lang="less" scoped>

.gamepointlist {
	width: 98%;
	padding: 10px;
	.btngroup {
		margin-top: 10px;
		display: flex;
		justify-content: flex-end;
	}
	.tablewrap {
		margin-top: 10px;
	}
	.paginationwrap {
		display: flex;
		margin-top: 20px;
		margin-bottom: 20px;
		justify-content: flex-end;
	}
	.hoverclass {
		cursor: pointer;
	}
	.hoverclass:hover {
		color: var(--el-color-primary);
	}
}
</style>
