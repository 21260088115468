<template>
	<div class="PlayerDetail">
		<BreadcrumbVue :navigatelist="navigatelist" />
		<div class="tablewrap">
			<el-table :data="tableData" style="width: 100%" empty-text="还没有数据哦~" stripe>
				<el-table-column
					v-for="(item, index) in tablecolumn"
					:key="index"
					:prop="item.prop"
					:label="item.label"
					:width="item.width"
					:sortable="item.sortable"
				>
					<template #default="scope">
						<div v-if="scope.row[item.prop] !== 0 && !scope.row[item.prop]">-</div>
						<div v-else-if="item.prop == 'createTime'">
							{{ dayjs(scope.row[item.prop]).format("YYYY-MM-DD HH:mm:ss") }}
						</div>
						<div v-else-if="item.prop == 'integral'">{{ scope.row[item.prop] }}</div>
						<div v-else>{{ scope.row[item.prop] }}</div>
					</template>
				</el-table-column>
			</el-table>
		</div>
	</div>
</template>

<script setup>
import { onMounted, reactive, ref } from "vue";
import BreadcrumbVue from "@/components/breadcrumb.vue";
import { useRouter } from "vue-router";
import { get } from "../../utils/request";
import { Decrypt } from "../../utils/secret";
import dayjs from "dayjs";
const router = useRouter();
const navigatelist = [
	{ key: 1, value: "游戏" },
	{ key: 2, value: "场次信息" },
	{ key: 3, value: "人数统计" },
];
let tableData = ref([]);
const tablecolumn = reactive([
	{
		prop: "id",
		label: "玩家编号",
		width: "",
	},
	{
		prop: "nickname",
		label: "昵称",
		width: "",
	},
	{
		prop: "career",
		label: "职业",
		width: "",
	},
	{
		prop: "grade",
		label: "等级",
		width: "",
	},
	{
		prop: "ranking",
		label: "排名",
		width: "",
	},
	{
		prop: "integral",
		label: "现有积分",
		width: "",
	},
]);
onMounted(() => {
	//先解码再解密
	if (router.currentRoute.value.query.gameData) {
		const encodedData = JSON.parse(
			Decrypt(decodeURIComponent(router.currentRoute.value.query.gameData))
		);
		console.log(encodedData);
		tablecolumn.map((item) => {
			if (item.prop === "career") {
				if (encodedData.gameId == 1) {
					item.label = "职业";
				} else {
					item.label = "玩家角色";
				}
			}
		});
		tableData.value.push(encodedData);
	}
});
</script>

<style lang="less" scoped>
.PlayerDetail {
	width: 98%;
	padding: 10px 10px;
}
.flexbox {
	display: flex;
	align-items: center;
	justify-content: center;
}
.tablewrap {
	margin-top: 20px;
}
.paginationwrap {
	display: flex;
	margin-top: 20px;
	margin-bottom: 20px;
	justify-content: flex-end;
}
</style>
