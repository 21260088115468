<template>
	<div class="PlayerFeedback">
		<BreadcrumbVue :navigatelist="navigatelist" />
		<div style="margin-top: 20px" class="formhead">
			<div class="row">
				<el-form-item label="用户昵称">
					<el-input v-model="nickname" placeholder="请输入用户昵称" />
				</el-form-item>
				<el-form-item label="反馈创建时间：">
					<el-date-picker
						v-model="timeArray"
						type="datetimerange"
						start-placeholder="开始时间"
						end-placeholder="结束时间"
					/>
				</el-form-item>
				<el-form-item label="游戏名称">
					<el-select v-model="gameId" placeholder="请选择游戏" style="marginleft: 20px">
						<el-option
							:label="item.name"
							:value="item.id"
							v-for="item in gamelist"
							:key="item.id"
						/>
					</el-select>
				</el-form-item>
			</div>
		</div>
		<div class="btngroup">
			<el-button @click="handleReset">重置</el-button>
			<el-button type="primary" @click="handleSearch">确认搜索</el-button>
		</div>
		<div class="tablewrap">
			<el-table
				:data="tableData"
				@sort-change="handleSortChange"
				style="width: 100%"
				empty-text="还没有数据哦~"
				stripe
			>
				<el-table-column
					v-for="(item, index) in tablecolumn"
					:key="index"
					:prop="item.prop"
					:label="item.label"
					:width="item.width"
					:sortable="item.sortable"
				>
					<template #default="scope">
						<div v-if="scope.row[item.prop] !== 0 && !scope.row[item.prop]">-</div>
						<div v-else-if="item.prop == 'createTime'" @click="handleupdate(scope.row, 'intergal')">
							{{ dayjs(scope.row[item.prop]).format("YYYY-MM-DD HH:mm:ss") }}
						</div>
						<div v-else>{{ scope.row[item.prop] }}</div>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div class="paginationwrap">
			<el-pagination
				class="paging fr"
				@current-change="handleCurrentChange"
				:current-page="page"
				@size-change="handleSizeChange"
				:page-sizes="[10, 20, 50, 100, 200]"
				:page-size="size"
				:pager-count="5"
				layout="total, sizes, prev, pager, next, jumper"
				:total="total"
			>
			</el-pagination>
		</div>
	</div>
</template>

<script setup>
import { onMounted, reactive, ref } from "vue";
import BreadcrumbVue from "@/components/breadcrumb.vue";
import { useRouter } from "vue-router";
import { get } from "../../utils/request";
import dayjs from "dayjs";
const router = useRouter();
const navigatelist = [
	{ key: 1, value: "玩家" },
	{ key: 2, value: "玩家反馈" },
];
const page = ref(1);
const size = ref(10);
const total = ref(0);
const dateSort = ref(0);
const nickname = ref(undefined);
const timeArray = ref([]);
const gameId = ref(undefined);
let tableData = ref([]);
const gamelist = ref([]);
const tablecolumn = reactive([
	{
		prop: "id",
		label: "反馈编号",
		width: "",
	},
	{
		prop: "userId",
		label: "用户编号",
		width: "",
	},
	{
		prop: "nickname",
		label: "用户昵称",
		width: "",
	},
	{
		prop: "gameName",
		label: "游戏名称",
		width: "",
	},
	{
		prop: "storeName",
		label: "商家名称",
		width: "",
	},
	{
		prop: "sessionId",
		label: "场次编号",
		width: "",
	},
	{
		prop: "createTime",
		label: "创建时间",
		width: "200",
		sortable: "custom",
	},
	{
		prop: "score",
		label: "评分",
		width: "80",
	},
	{
		prop: "content",
		label: "反馈内容",
		width: "250",
	},
]);
onMounted(() => {
	getlist();
	getGameList();
});
const getGameList = () => {
	get(`/manager/game/list`, {}, false).then((res) => {
		if (res.code === 1) {
			let list = res.result;
			list.unshift({
				name: "全部",
				id: "",
			});
			gamelist.value = list;
		}
	});
};
const getlist = () => {
	let url = `/manager/player/feedback/list?page=${page.value}&size=${size.value}`;
	if (timeArray.value.length > 0) {
		url =
			url +
			`&start=${dayjs(timeArray.value[0]).format("YYYY-MM-DD HH:mm:ss")}&end=${dayjs(
				timeArray.value[1]
			).format("YYYY-MM-DD HH:mm:ss")}`;
	}
	if (nickname.value) {
		url = url + `&nickname=${nickname.value}`;
	}
	if (gameId.value !== undefined) {
		url = url + `&gameName=${gameId.value}`;
	}
	if (dateSort.value) {
		url = url + `&dateSort=${dateSort.value}`;
	}
	get(url).then((res) => {
		if (res.code == 1) {
			tableData.value = res.result.list;
			total.value = res.result.total;
		}
	});
};
const handleSearch = () => {
	getlist();
};
const handleReset = () => {
	nickname.value = undefined;
	timeArray.value = [];
	gameId.value = undefined;
	getlist();
};
const handleCurrentChange = (val) => {
	page.value = val;
	getlist();
};
const handleSizeChange = (val) => {
	size.value = val;
	getlist();
};
const handleSortChange = ({ column, prop, order }) => {
	dateSort.value = 0;
	if (prop == "createTime") {
		if (order == "ascending") {
			//升序
			dateSort.value = 1;
		} else if (order == "descending") {
			//降序
			dateSort.value = 2;
		} else {
			dateSort.value = 0;
		}
	}
	getlist();
};
</script>

<style lang="less" scoped>
.PlayerFeedback {
	width: 98%;
	padding: 10px 10px;
}
.flexbox {
	display: flex;
	align-items: center;
	justify-content: center;
}
.btngroup {
	margin-top: 10px;
	display: flex;
	justify-content: flex-end;
}
.tablewrap {
	margin-top: 10px;
}
.paginationwrap {
	display: flex;
	margin-top: 20px;
	margin-bottom: 20px;
	justify-content: flex-end;
}
.el-input {
	width: 200px;
}
.formhead {
	.row {
		display: flex;
		> div {
			margin-left: 20px;
		}
		> div:first-child {
			margin-left: 0px;
		}
	}
	/deep/ .el-range-editor.el-input__wrapper {
		width: 350px;
	}
}
</style>
