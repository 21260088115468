<template>
    <div class="shoplist">
        <BreadcrumbVue :navigatelist="navigatelist"/>
        <el-form :model="form" label-width="120px" style="margin-top: 30px;">
            <el-form-item label="名称">
                <el-input v-model="form.nickname" placeholder="请输入主持人名称"/>
            </el-form-item>
            <el-form-item label="账号">
                <el-input v-model="form.username" placeholder="请输入主持人手机号"/>
            </el-form-item>
            <el-form-item label="密码">
                <el-input v-model="form.password" type="password" placeholder="请输入主持人密码" show-password/>
            </el-form-item>
            <el-form-item label="备注">
                <el-input v-model="form.notes" maxlength="50" placeholder="请输入备注"/>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSubmit('confirm')">确定</el-button>
                <el-button @click="onSubmit('cancel')">取消</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script setup>

    import BreadcrumbVue from "@/components/breadcrumb.vue";
    import { reactive } from 'vue'
    import { post } from '../../utils/request'
    import { ElMessage } from 'element-plus'
    import { useRouter } from "vue-router"
    import {  Decrypt} from '../../utils/secret'
    import { oncePress } from '../../utils/limitClick'


    const router = useRouter();

    let getEditData = JSON.parse(Decrypt(decodeURIComponent(router.currentRoute.value.query.emceeData)));
    console.log(getEditData)
    // do not use same name with ref
    const form = reactive({
        id: getEditData ? getEditData.id : '',
        shopId: getEditData ? getEditData.storeId : '',
        nickname: getEditData ? getEditData.nickname : '',
        username: getEditData ? getEditData.username : '',
        password: getEditData ? getEditData.password : '',
        notes: getEditData ? getEditData.notes : '',
    });

    const navigatelist=[
        {key:1,value:'商家'},
        {key:2,value:'商家信息'},
        {key:3,value:'主持人'},
        {key:4,value:'新增主持人'},
    ];

    const open = () => {
        ElMessage({
            message: form.id ? '编辑成功' : '新增成功',
            grouping: true,
            type: 'success',
        })
    };

    const onSubmit =(type)=> {
        if (type === 'confirm') {
            oncePress.resTime = 2000
            oncePress.onPress(()=>{
                let submitData = {
                id: form.id ? form.id : '',
                storeId: form.shopId,
                username: form.username,
                password: form.password,
                nickname: form.nickname,
                notes: form.notes
                };
                // console.log(submitData)
                post(`/manager/host`, submitData).then(res=>{
                    // console.log(res)
                    if (res.code === 1) {
                        open();
                        router.back();
                    }
                })
            })
        } else {
            router.back();
        }
    }

</script>

<style lang="less" scoped>
    .shoplist{
        padding: 10px;
        .btngroup{
            margin-top: 10px;
            display: flex;
            justify-content: flex-end;
        }
        .tablewrap{
            margin-top: 20px;
        }
        .paginationwrap{
            display: flex;
            margin-top: 20px;
            margin-bottom: 20px;
            justify-content: flex-end;
        }
    }
    .el-input {
        width: 300px;
    }
</style>