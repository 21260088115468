<template>
  <div class="boss">
    <div class="boss_left boss_item">
      <el-tree
        :data="data"
        :props="defaultProps"
        @node-click="treeClick"
        node-key="id"
        ref="treeRef"
        highlight-current
      ></el-tree>
    </div>
    <div class="boss_right boss_item">
      <div class="top">
        <el-button type="primary" @click="append('province')">新增省</el-button>
        <el-button type="primary" @click="append('editTwo')" v-if="currentSelectId">修改</el-button>
        <el-button type="primary" @click="append('subset')" v-if="currentSelectId">新增子下级</el-button>
        <el-button type="danger" @click="deleteBranch" v-if="currentSelectId">删除</el-button>
      </div>
      <div class="bottom">
        <div class="title">
          当前提交类型：{{newType === "province"?"新增省份":newType === "edit" || newType === "editTwo"?"修改":"新增子下级"}}
          <text></text>
        </div>
        <el-form
          ref="form"
          :model="formData"
          label-width="100px"
          :rules="rules"
          class="demo-ruleForm"
        >
<!--          <el-form-item label="祖区划编号:">-->
<!--            <el-input v-model="formData.ancestors" disabled/>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="父区划编号:">-->
<!--            <el-input v-model="formData.parentId" disabled/>-->
<!--          </el-form-item>-->
          <el-form-item label="区划名称:" prop="name">
            <el-input v-model="formData.name" />
          </el-form-item>
          <el-form-item label="区划排序：">
            <el-input v-model="formData.sort" />
          </el-form-item>
          <el-form-item label="备注：">
            <el-input type="textarea" v-model="formData.remark" />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submit">提交</el-button>
            <el-button>清空</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script setup>
import { reactive, onMounted, ref } from "vue";
import { post, get } from "../../utils/request";
import { ElMessageBox, ElMessage } from "element-plus";
onMounted(() => {
  getDistrict();
});
const data = ref([]);
const defaultProps = {
  children: "children",
  label: "name",
};
// 表单
const formData = reactive({
  ancestors: "",
  id: "",
  name: "",
  parentId: "",
  regionLevel: "",
  remark: "",
  sort: "",
});
const rules = {
  ancestors: [{ required: true, message: "请输入祖区划编号", trigger: "blur" }],
  name: [{ required: true, message: "请输入区划名称", trigger: "blur" }],
  regionLevel: [{ required: true, message: "请输入层级", trigger: "blur" }],
};
// 获取地区列表列表
const getDistrict = async () => {
  const { code, result } = await get(`/manager/region/lazy-list`);
  if (code != 1) return;
  data.value = result;
};
// 选中树形列表的数据
const parentLevelSelectId = ref(""); // 父级ID
const currentSelectId = ref(""); // 当前选中ID
const treeHierarchy = ref(); // 选中层级
const addType = ref("add");
const treeClick = (data, node, nodes) => {
  newType.value = "edit"
  addType.value = "edit";
  treeHierarchy.value = node.level
  currentSelectId.value = data.id
  getDetails();
};
// 获取选中详情
const getDetails = async () => {
  const { code, result } = await get(
    `/manager/region/detail?id=${currentSelectId.value}`
  );
  if (code != 1) return;
  for (const item in formData) {
    formData[item] = result[item];
  }
};
// 点击新增
const newType = ref("province")
const append = (e) => {
  newType.value = e
  for (const item in formData) {
    formData[item] = "";
  }
  if (e === "subset") formData.parentId = currentSelectId.value
  if (e === "editTwo") getDetails()
  addType.value = "add";
};
// 提交
const submit = async () => {
  const params = {};
  for (const item in formData) {
    if (formData[item] != "") params[item] = formData[item];
  }
  const { code } = await post(`/manager/region/submit`, params);
  if (code != 1) return;
  getDistrict()
  ElMessage({
    message: "提交成功",
    type: "success",
  });
};
// 删除
const deleteBranch = () => {
  ElMessageBox.confirm("您确定要删除吗?", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(async () => {
      const { code } = await post(
        `/manager/region/remove?id=${currentSelectId.value}`
      );
      if (code != 1) return;
      getDistrict()
      for (const item in formData) {
        formData[item] = "";
      }
      ElMessage({
        message: "删除成功",
        type: "success",
      });
    })
    .catch(() => {
      ElMessage({
        message: "已取消",
        type: "success",
      });
    });
};
</script>
<style scoped lang="less">
.boss {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background: #eee;
  padding: 10px;
  box-sizing: border-box;
  .boss_item {
    flex: 1;
    height: 100%;
    box-sizing: border-box;
  }
  .boss_left {
    background: white;
    padding: 10px;
  }
  .boss_right {
    padding: 0 10px;
    .top {
      padding: 10px;
      background: white;
    }
    .bottom {
      margin-top: 10px;
      padding: 10px;
      background: white;
      height: calc(100% - 62px);
      box-sizing: border-box;
      .title{
        margin-bottom: 20px;
      }
    }
  }
}
</style>
